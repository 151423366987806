// WRITE ARTICLE
import { FolderPlusIcon } from "@heroicons/vue/20/solid";

export const addFilesToDatapoints = {
  order: 0,
  name: "Add files to datapoints",
  subcategory: "Notes & Files",
  icon: FolderPlusIcon,
  iconColor: "text-amber-600",
  markdown: `# Add files to datapoints`,
};
